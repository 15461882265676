@import "@ashishdotme/ui/styles/_theme.sass";

.hero {
  background: #A770EF;
  background: -webkit-linear-gradient(to right, #FDB99B, #CF8BF3, #A770EF);
  background: linear-gradient(to right, #FDB99B, #CF8BF3, #A770EF);
}

.card {
  border-radius: 5px
}

@media screen and (min-width: 1472px) {
  .container {
    max-width: 1344px;
    width: 1344px;
  }
}
